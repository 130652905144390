@font-face {
    font-family: 'Futura';
    src: url('./fonts/WorkSans-Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'The Seasons';
    src: url('./fonts/TheSeasons-Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Futura', sans-serif;
}

.font-the-seasons {
    font-family: 'The Seasons', serif;
}